<template>
  <funds-page  :is-iam-k-i="getUSER.qualifiedInvestor" :funds-list="this.getFundsList" @onPush="goPush" @buy="goBuy" />
</template>

<script>
import FundsPage from "../../../components/pages/cabinet/Funds/FundsPage";
import { mapActions, mapGetters } from "vuex";
import RWMBuyPushModalMixin from "../../../components/mixins/RWMBuyPushModalMixin";

export default {
  name: "Funds",
  components: { FundsPage },
  mixins: [RWMBuyPushModalMixin],
  watch: {
    fondChanged() {
      this.GET_FUNDS();
    },
    userChanged() {
      this.GET_FUNDS();
    },
    docChanged() {
      this.GET_FUNDS();
    },
  },
  computed: {
    ...mapGetters("FundsModule", ["getFundsList"]),
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("AutoUpdateModule", ["fondChanged", "userChanged", "docChanged"])
  },
  methods: {
    ...mapActions("FundsModule", ["GET_FUNDS"]),

    goPush({to, fundKI, title, isDetales, kiStatus}) {
      this.push({
        to: to,
        fundKI: fundKI,
        title: title,
        isDetales: isDetales,
        isQualifiedInvestor: this.getUSER.qualifiedInvestor,
        kiStatus: kiStatus,
      })
    },
    goBuy({toBuy, fundStatus, fundAnkets, title, fundKI, hasNecessaryDocs, fatca_status, podft_status, kiStatus})
    {
      this.buy({
        toBuy: toBuy,
        fundStatus: fundStatus,
        fundAnkets: fundAnkets,
        title: title,
        fundKI: fundKI,
        hasNecessaryDocs: hasNecessaryDocs,
        fatca_status: fatca_status,
        podft_status: podft_status,
        kiStatus: kiStatus,
        isQualifiedInvestor: this.getUSER.qualifiedInvestor
      });
    },
  },
  mounted() {
    this.GET_FUNDS();
  },
};
</script>

<style scoped lang="scss"></style>
