<template>
    <div class="rwm-fund-card">
        <div class="rwm-fund-card_title">
            <div v-if="fundKi" style="height: 160px; position: relative; width: 0px;">
                <rwm-qualified-mark text="Паи инвестиционного фонда ограничены в обороте и могут выдаваться только квалифицированным инвесторам"/>
            </div>
            <div class="rwm-fund-card_title_img">
                <img :src="fundLogo" />
            </div>
            <div class="rwm-fund-card_title_text fund_name"
                 v-html="title"
                 @click="detales ? $emit('onPush', `/cabinet/funds/fund/${toDetails}`) : ''"
            />
            <div class="rwm-fund-card_title_text fund_short_name"
                 v-html="short_title"
                 @click="detales ? $emit('onPush', `/cabinet/funds/fund/${toDetails}`) : ''"
            />
        </div>
        <div class="rwm-fund-card_body">
            <div class="rwm-fund-card_body_text" v-html="description" />
            <div class="rwm-fund-card_body_actions">
                <div class="rwm-fund-card_body_actions_action">
                    <rwm-button :disabled="!detales && !fundKi" outlined width="100%" @click="$emit('onPush', `/cabinet/funds/fund/${toDetails}`)">Подробнее</rwm-button>
                </div>
<!--                :class="{'invisible' : !toBuy}"-->
                <div class="rwm-fund-card_body_actions_action">
                    <rwm-button width="100%" @click="$emit('buy', {toBuy, title})">Купить</rwm-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RwmButton from "../RWMButton/RwmButton";
    import RwmQualifiedMark from "../RWMQualifiedMark/RwmQualifiedMark";
    export default {
        name: "RwmFundCard",
        components: {RwmQualifiedMark, RwmButton},
        props: {
            title: {
                type: String,
                default: "",
            },
            short_title: {
                type: String,
                default: "",
            },
            fundLogo: {
                type: String,
                default: null
            },
            description: String,
            toDetails: {
                type: [String, Number],
                default: null
            },
            toBuy: {
                type: [String, Number],
                default: null
            },
          detales: {
              type: Boolean,
              default: true,
          },
          isIamKI: {
            type: Boolean,
            default: false,
          },
          fundKi: {
            type: Boolean,
            default: false,
          }
        },
    }
</script>

<style scoped lang="scss">
    .rwm-fund-card {
        box-sizing: border-box;
        border: 1px solid $RWM-grey-light;
        border-radius: 5px;
        cursor: default;
        width: 100%;
        @media screen and (min-width: 1280px) {
            width: calc(50% - 16px);
            display: flex;
            flex-direction: column;
        }
        &_title {
            height: 160px;
            max-height: 160px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $RWM-grey-light;
            //overflow: hidden;
            &_img {
                display: none;
                @media screen and (min-width: 600px) {
                    display: block;
                    flex: 0 1 40%;
                    overflow: hidden;
                    margin-left: 32px;
                    max-height: inherit;
                    align-self: flex-end;
                    text-align: center;
                }
                & img {
                    object-fit: contain;
                    max-width: 100%;
                    max-height: inherit;
                }
            }
            &_text {
                flex: 1 1 60%;
                font-weight: bold;
                font-size: 25px;
                line-height: 34px;
                color: $RWM-red;
                padding: 10px 32px;
                cursor: pointer;
            }
        }
        &_body {
            margin: 32px;
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            flex: 1 1 auto;
            &_text {
                font-size: 18px;
                line-height: 25px;
                color: $RWM-grey;
                flex: 1 1 auto;
            }
            &_actions {
                display: flex;
                flex-direction: column;
                row-gap: 18px;
                @media screen and (min-width: 600px) {
                    flex-direction: row;
                    column-gap: 40px;
                }
                &_action {
                    flex: 1;
                    @media screen and (min-width: 600px) {
                        max-width: 50%;
                    }
                    /*& .invisible {*/
                    /*    opacity: 0;*/
                    /*    cursor: default !important;*/
                    /*}*/
                }
            }
        }
    }
</style>
